
import { Component, Vue, Ref } from 'vue-property-decorator'
import ReportsService from '../../ReportsService'
import { ReportModel } from '../../models/ReportModel'
import Modal from '@/components/Modal.vue'
import BaseIcon from '@/components/base/BaseIcon.vue'
import BaseButton from '@/components/base/BaseButton.vue'
import DropDownButton from '@/components/forms/DropDownButton.vue'

type ReportType = 'report' | 'act'

@Component({
  name: 'DocumentList',
  components: {
    Modal,
    BaseIcon,
    BaseButton,
    DropDownButton,
  },
})
export default class DocumentList extends Vue {
  @Ref() readonly download!: HTMLAnchorElement

  loading = false
  reportList = [] as ReportModel[]
  errorMessage = ''
  showErrorModal = false

  created(): void {
    this.getReports()
  }

  async getReports(): Promise<void> {
    this.loading = true
    const reports = await ReportsService.getReports()
    if (reports instanceof Array) {
      if (reports.length) {
        reports.sort((a, b) => b.id - a.id)
        this.reportList = reports
      }
    }
    this.loading = false
  }

  async downloadReport(id: number, type: ReportType): Promise<void> {
    try {
      const blob = await ReportsService.downloadReport(id, type)
      const url = window.URL.createObjectURL(blob)
      this.download.href = url
      this.download.download = `${id}-${type}`
      this.download.click()
    } catch (error) {
      this.errorMessage = ''
      if (ReportsService.isServiceError(error)) {
        try {
          const data = JSON.parse(await error.response?.data?.text())
          this.errorMessage = data?.message
        } catch {
          this.errorMessage = error.message
        }
      }
      this.errorMessage = this.errorMessage || 'Неизвестная ошибка'
      this.showErrorModal = true
    }
  }
}
